const footerMenuList = [
  {
    id: 1,
    title: "footer.courses.inside.title",
    diffClass: "",
    list: [
      {
        id: 1,
        text: "footer.courses.inside.sofia-bansko",
      },
      {
        id: 2,
        text: "footer.courses.inside.sofia-plovdiv",
      },
      {
        id: 3,
        text: "footer.courses.inside.sofia-borovets",
      },
      {
        id: 4,
        text: "footer.courses.inside.sofia-burgas",
      },
      {
        id: 5,
        text: "footer.courses.inside.sofia-pamporovo",
      },
      {
        id: 6,
        text: "footer.courses.inside.sofia-varna",
      },
      {
        id: 7,
        text: "footer.courses.inside.sofia-hotel",
      },
    ],
  },
  {
    id: 2,
    title: "footer.courses.outside.title",
    diffClass: "",
    list: [
      {
        id: 1,
        text: "footer.courses.outside.sofia-istanbul",
      },
      {
        id: 2,
        text: "footer.courses.outside.sofia-bucharest",
      },
      {
        id: 3,
        text: "footer.courses.outside.sofia-thessaloniki",
      },
      {
        id: 4,
        text: "footer.courses.outside.sofia-athens",
      },
      {
        id: 5,
        text: "footer.courses.outside.sofia-belgrade",
      },
      {
        id: 6,
        text: "footer.courses.outside.sofia-skopie",
      },
    ],
  },
];

export { footerMenuList };
